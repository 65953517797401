import React from 'react';
import {
  IntersectionAnimation,
  LayoutProvider,
  SliceHeader
} from '@lam-agency/toolkit/components';
import { useMVT } from '@lam-agency/toolkit/stores';
import cn from 'classnames';
import {
  parseButtonDataFromFigma,
  parseImagesFromFigma
} from '@lam-agency/toolkit/utils/figma';

import * as styles from './styles.module.scss';

interface IProps {
  data: {
    fields: {
      heading: string;
      images: string[];
      buttonText: string;
    };
  };
}

export const FigmaImageGrid = ({ data: { fields } }: IProps) => {
  const { projectString } = useMVT();

  const { heading, images, buttonText } = fields || {};

  const imageGridItems = parseImagesFromFigma(projectString, images)
    .map((item) => item?.image)
    .filter((image): image is string => image !== undefined);

  const buttons = parseButtonDataFromFigma([buttonText]);

  console.log(imageGridItems);

  const usePolaroidFrame = imageGridItems.every((item) =>
    item.includes('art-direction')
  );

  if (!imageGridItems?.[0]) return null;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LayoutProvider paddingX paddingY>
          <SliceHeader data={{ heading, buttons }} />

          <div className={cn(styles.grid)}>
            {imageGridItems.map((image, i) => (
              <IntersectionAnimation
                key={i}
                className={styles.gridItemWrapper}
                animation="fadeGrow"
                delay={i * 50}
              >
                <figure
                  className={cn(usePolaroidFrame && styles.polaroidFrame)}
                >
                  <img className={styles.image} src={image} />
                </figure>
              </IntersectionAnimation>
            ))}
          </div>
        </LayoutProvider>
      </div>
    </div>
  );
};
