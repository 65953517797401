// extracted by mini-css-extract-plugin
export var container = "styles-module--container--6136f";
export var content = "styles-module--content--58f72";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--fff22";
export var gridItemWrapper = "styles-module--gridItemWrapper--91f27";
export var image = "styles-module--image--02b4a";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var polaroidFrame = "styles-module--polaroidFrame--7e0da";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";